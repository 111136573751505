#klickly-admin-root {
    height: 100%;

    .trigger {
        position: absolute;
        right: 0;
        background-color: rgba(255, 255, 255, 0.65);
        transform: translateX(100%);
        font-size: 18px;
        height: 64px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color .3s;
        z-index: 1;

        &:hover {
            color: #1890ff;
        }
    }

    .logo {
        height: 48px;
        margin: 16px;

        &__img {
            padding: 5px;
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .select {
        &__container {
            margin: 0;
            padding: 0;
            font-size: 0;
        }
    }

    .left-menu {
        .anticon {
            font-size: 20px;
        }
    }
}
.ant-layout.ant-layout-has-sider>.ant-layout, .ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow: visible;
}
.layout-content {
    margin: 24px 16px;
    min-height: 280px;
}

.main-spinner {
    .ant-spin-spinning {
        min-height: 80vh;
    }
}

.width-100 {
    width: 100%;
}

.modal__form {
    .ant-form-item-label {
        text-align: left;
    }
}

.remove-table {
    td:nth-child(2) {
        color: #1890ff;
    }
}

.header {
    height: 64px;
    padding: 0 12px 0 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    position: relative;
}

:global {
    .ant-layout {
        min-height: 100vh;
    }
}

.menu {
    :global(.anticon) {
        margin-right: 8px;
    }
}

i.trigger {
    font-size: 20px;
    line-height: 64px;
    cursor: pointer;
    transition: all 0.3s, padding 0s;
    padding: 0 24px;
    &:hover {
        background: #1890ff;
    }
}

.right {
    float: right;
    height: 100%;
    .action {
        cursor: pointer;
        padding: 0 12px;
        display: inline-block;
        transition: all 0.3s;
        height: 100%;
        > i {
            font-size: 16px;
            vertical-align: middle;
            color: fade(#000, 85%);
        }
        &:hover,
        &:global(.ant-popover-open) {
            background: #1890ff;
        }
    }
    .search {
        padding: 0;
        margin: 0 12px;
        &:hover {
            background: transparent;
        }
    }
    .account {
        .avatar {
            margin: 20px 8px 20px 0;
            color: #1890ff;
            background: rgba(255, 255, 255, 0.85);
            vertical-align: middle;
        }
    }
}

@media only screen and (max-width: 768px) {
    .header {
        :global(.ant-divider-vertical) {
            vertical-align: unset;
        }
        .name {
            display: none;
        }
        i.trigger {
            padding: 0 12px;
        }
        .logo {
            padding-right: 12px;
            position: relative;
        }
        .right {
            position: absolute;
            right: 12px;
            top: 0;
            background: #fff;
            .account {
                .avatar {
                    margin-right: 0;
                }
            }
        }
    }
}

.vh-height {
    min-height: 100vh;
}

.ant-form-item-label {
    text-overflow: ellipsis;
}

.m {
    &-0 {
        margin: 0;
    }

    &-left {
        &-200 {
            margin-left: 200px;
        }

        &-80 {
            margin-left: 80px;
        }
    }
}

.text {
    &-capitalize {
        text-transform: capitalize;
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }
}

.width-100 {
    width: 100%;
}

.page-title {
    margin-bottom: 20px;
}

.table__actions-button {
    width: 180px;
}

.menu {
    &__wrapper {
        position: fixed;
        height: 100vh;
        z-index: 1;
        left: 0;
    }
}

.h-100 {
    height: 100vh;
}

.ant-notification-notice-description {
    &:first-letter {
        text-transform: uppercase;
    }
}

.table {
    thead tr:nth-child(1) th{
        background: white;
        position: sticky;
        top: 0;
        z-index: 10;
    }
}

.avatar-wrapper {
    .ant-avatar {
        width: 165px;
        height: 165px;
        border-radius: 50%;
        line-height: 200px;
        font-size: 165px;
    }
}

.common-block {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-bottom: 16px;
    overflow: auto;

    &.account-settings {
        margin-top: 24px;
    }

    .left-menu {
        width: 170px;
        border-right: 1px solid hsv(0, 0, 94%);
        :global {
            .ant-menu-inline {
                border: none;
            }
            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                font-weight: bold;
            }
        }
    }
    .right-block {
        flex: 1;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
        .title {
            font-size: 20px;
            color: fade(#000, 85%);
            line-height: 28px;
            font-weight: 500;
            margin-bottom: 12px;
        }
    }
    :global {
        .ant-list-split .ant-list-item:last-child {
            border-bottom: 1px solid #e8e8e8;
        }
        .ant-list-item {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }
}

@media screen and (max-width: 768px) {
    .account-settings {
        flex-direction: column;
        .left-menu {
            width: 100%;
            border: none;
        }
        .right-block {
            padding: 40px;
        }
    }
}

.main-grid-content {
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: 0.3s;
    &.wide {
        max-width: 1200px;
        margin: 0 auto;
    }
}

.card-header-info {
    position: relative;
    text-align: center;

    & > span {
        color: fade(#000, 45%);
        display: inline-block;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 4px;
    }

    & > p {
        color: fade(#000, 85%);
        font-size: 24px;
        line-height: 32px;
        margin: 0;
    }

    & > em {
        background-color: hsv(0, 0, 94%);
        position: absolute;
        height: 56px;
        width: 1px;
        top: 0;
        right: 0;
    }
}

@media screen and (max-width: 576px) {
    .card-header-info {
        margin-bottom: 16px;

        & > em {
            display: none;
        }
    }
}

.extra-content-search {
    margin-left: 16px;
    width: 300px;
}

.account-invoices-list {
    .ant-list-item-content {
        display: block;
    }
}

.refund-small-title {
    margin-left: 5px;
    font-size: 12px;
    color: fade(#000, 85%);
}

.ant-badge-grey {
    background-color: #fff;
    color: #999;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.settings-form-wrapper {
    min-height: 250px;
}


.ant-radio-button-wrapper.option-accept {
    &:hover {
        color: #52c41a;
    }
}

.ant-radio-button-wrapper-checked.option-accept {
    border-color: #52c41a !important;
    color: #52c41a;

    &:first-child {
        border-color: #52c41a;
        color: #52c41a;

    }

    &:hover {
        border-color: #52c41a;
        color: #52c41a;

        &:before {
            background-color: #52c41a;
        }
    }

    &:before {
        background-color: #52c41a;
    }
}

.ant-radio-button-wrapper.option-reject {
    &:hover {
        color: #fa541c;
    }
}

.ant-radio-button-wrapper-checked.option-reject {
    border-color: #fa541c;
    color: #fa541c;

    &:hover {
        border-color: #fa541c;
        color: #fa541c;

        &:before {
            background-color: #fa541c;
        }
    }

    &:before {
        background-color: #fa541c;
    }
}

.no-margin-for-expand .ant-table {
    margin: 0 !important;
}

.remaining-amount {
    font-weight: bold;
    font-size: 16px;
}

.profit-partial {
    background-color: #fffbe6;

    .ant-table-cell-fix-left {
        background-color: #fffbe6;
    }
}

.profit-none {
    background-color: #ffe1df;

    .ant-table-cell-fix-left {
        background-color: #ffe1df;
    }
}

.profit-disabled {
    background-color: #f2f2f2;

    .ant-table-cell-fix-left {
        background-color: #f2f2f2;
    }
}

.form-item-margin-bottom {
    margin-bottom: 0 !important;
}

.form-item-input-no-margin-bottom {
    margin-bottom: 0 !important;
}

.form-item-input-margin-bottom {
    margin-bottom: 10px !important;
}

.category-add-new-item {
    color: #000;
    cursor: pointer;
}
