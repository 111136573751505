.main-container {
    height: 100%;
}

.main-background {
    background: url("../../../../images/bg-login-page.svg") 0 0 no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    position: fixed;
}

.main-box {
    position: absolute;
    width: 500px;
    top: 20%;
    margin-bottom: 30px;
    left: 50%;
    margin-left: -250px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    overflow: hidden;

    &.mfa-setup {
        top: 10px;
        width: 640px;
        margin-left: -320px;
    }
}

.main-logo {
    width: 100%;
    height: 100px;
    background: #f5f4f5;
    text-align: center;
    border-bottom: 3px solid #cfcecf;
}

.main-logo__img {
    width: 161px;
    height: 57px;
    position: relative;
    top: 25px;
}

.main-logo__title {
    font-size: 25px;
    color: #4a4a4a;
    font-family: proxima_novalight, sans-serif;
}

.main-content {
    width: 100%;
    padding: 30px;
}

.mfa-setup {
    .main-content {
        padding: 15px;
    }

    .ant-divider {
        margin: 10px 0;
    }
}

.login-form-button {
    width: 100%;
}

.error-message-padding {
    margin-bottom: 10px;
}

.sign-in-manual-text {
    font-size: 12px;
    text-decoration: underline;
    color: #4a4a4a;
    font-family: proxima_novaregular, sans-serif;
    text-align: center;
    margin-top: 50px;
    cursor: pointer;
}
